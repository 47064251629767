import jQuery from 'jquery'
import popper from 'popper.js'
import bootstrap from 'bootstrap'
// import wolfy87Eventemitter from 'wolfy87-eventemitter'
// import * as boutique from './jquery.boutique.js'

/*!
 * Title: Boutique carousel (jQuery plugin)
 * Author: Berend de Jong, Frique
 * Author URI: http://frique.me/
 * Version: 1.5.4 (2015-08-14 22:45)
 */
!(function(a) {
  a.fn.boutique = function(b) {
    var c = a.extend(
        {
          container_width: 'auto',
          front_img_width: 280,
          front_img_height: 'auto',
          variable_container_height: !0,
          frames: 5,
          starter: 1,
          speed: 600,
          front_topmargin: 40,
          behind_topmargin: 20,
          back_topmargin: 0,
          behind_opacity: 0.4,
          back_opacity: 0.15,
          behind_size: 0.7,
          back_size: 0.4,
          behind_distance: 'auto',
          autoplay: !1,
          autoplay_interval: 3e3,
          stop_autoplay_on_click: !1,
          freescroll: !0,
          hovergrowth: 0.08,
          easing: 'easeIOBoutique',
          move_more_easein: 'easeInBoutique',
          move_more_easebetween: 'linear',
          move_more_easeout: 'easeOutBoutique',
          move_more_directly: !1,
          never_move_twice: !1,
          text_front_only: !1,
          text_opacity: 0.7,
          keyboard: !0,
          move_on_hover: !1,
          right_to_left: !1,
        },
        b
      ),
      d = 'function' == typeof a.fn.velocity
    return (
      (a.fn.boutique_animate = d ? a.fn.velocity : a.fn.animate),
      (a.fn.boutique_stop = d
        ? function() {
            return a(this).velocity('stop'), a(this)
          }
        : a.fn.stop),
      (a.fn.boutique_fadeout = d
        ? function(b, c) {
            return (
              a(this).velocity('fadeOut', { duration: b, complete: c }), a(this)
            )
          }
        : a.fn.fadeOut),
      d &&
        ((c.easing = 'ease-in-out'),
        (c.move_more_easein = 'easeInQuad'),
        (c.move_more_easebetween = 'linear'),
        (c.move_more_easeout = 'easeOutQuad')),
      a.each(this, function() {
        function b() {
          var a = parseInt(y.width(), 10)
          x.width(a),
            'auto' === c.behind_distance &&
              ((Y = Math.round(a / 4 - lb / 2)), n.css({ left: Y })),
            (vb = Math.round(a / 2 - db / 2)),
            (xb = a - Y - lb),
            (wb = a - tb),
            o.css({ left: vb }),
            p.css({ left: xb }),
            q.css({ left: wb })
        }
        function d() {
          r && (clearInterval(r), (r = !1))
        }
        function e() {
          r && d(),
            (r = setInterval(function() {
              c.right_to_left ? n.trigger('click') : p.trigger('click')
            }, c.autoplay_interval))
        }
        function f(b, g) {
          var l,
            r,
            s = c.speed
          if (
            ((B = !0),
            (g = g || !1),
            g || (g = b > 1 ? 'first' : 'normal'),
            b > 1 && 'first' === g
              ? ((r = c.move_more_easein), (s = Math.round(0.7 * c.speed)))
              : b > 1
              ? ((r = c.move_more_easebetween), (s = Math.round(0.4 * c.speed)))
              : (r = 'last' === g ? c.move_more_easeout : c.easing),
            c.autoplay && d(),
            D === E - 2
              ? (v = 1)
              : D === E - 1
              ? ((v = 2), v > E && (v = 1))
              : D === E
              ? ((v = 3), v > E && (v = 1))
              : (v = D + 3),
            'function' == typeof pre_move_callback)
          ) {
            var t = p.children('a').attr('href')
            t || (t = 'no_anchor'),
              pre_move_callback(t, A, p.data('framesource'))
          }
          H && (x.boutique_stop(), c.text_front_only && o.find('.' + M).hide()),
            m
              .removeClass('frame1')
              .addClass('remove')
              .css('zIndex', -1),
            (h = n
              .removeClass('frame2')
              .addClass('frame1')
              .boutique_stop()
              .boutique_animate(
                { opacity: c.back_opacity, left: 0, top: X },
                s,
                r
              )),
            (l = h.find('.' + O).boutique_stop()),
            G && K && l.css({ height: 'auto' }),
            l.boutique_animate(Lb, s, r),
            c.text_front_only ||
              (h
                .find('.' + N)
                .boutique_stop()
                .boutique_animate({ fontSize: pb }, s, r),
              h
                .find('.' + M)
                .boutique_stop()
                .boutique_animate(Eb, s, r)),
            setTimeout(function() {
              h.css('zIndex', 1)
            }, s / 4),
            (i = o
              .removeClass('frame3')
              .addClass('frame2')
              .boutique_stop()
              .boutique_animate(
                { opacity: c.behind_opacity, left: Y, top: W },
                s,
                r
              )),
            i
              .find('.' + N)
              .boutique_stop()
              .boutique_animate({ fontSize: hb }, s, r),
            i
              .find('.' + M)
              .boutique_stop()
              .boutique_animate(Db, s, r),
            (l = i.find('.' + O).boutique_stop()),
            G && K && l.css({ height: 'auto' }),
            l.boutique_animate(Nb, s, r),
            setTimeout(function() {
              i.css('zIndex', 2)
            }, s / 4),
            (j = p
              .removeClass('frame4')
              .addClass('frame3')
              .boutique_stop()
              .boutique_animate(
                { opacity: 1, left: vb, top: V },
                s,
                r,
                function() {
                  H &&
                    c.text_front_only &&
                    a(this)
                      .find('.' + N)
                      .boutique_stop()
                      .css({ fontSize: _ })
                      .end()
                      .find('.' + M)
                      .boutique_stop()
                      .css(Cb)
                      .fadeIn(200),
                    H && K && c.variable_container_height && Jb(!0)
                }
              )),
            (l = j.find('.' + O).boutique_stop()),
            G && K && l.css({ height: 'auto' }),
            l.boutique_animate(Ob, s, r),
            (H && c.text_front_only) ||
              (j
                .find('.' + N)
                .boutique_stop()
                .boutique_animate({ fontSize: _ }, s, r),
              j
                .find('.' + M)
                .boutique_stop()
                .show()
                .boutique_animate(Cb, s, r)),
            setTimeout(function() {
              j.css('zIndex', 3)
            }, s / 4),
            (k = q
              .removeClass('frame5')
              .addClass('frame4')
              .boutique_stop()
              .boutique_animate(
                { opacity: c.behind_opacity, left: xb, top: W },
                s,
                r
              )),
            (l = k.find('.' + O).boutique_stop()),
            G && K && l.css({ height: 'auto' }),
            l.boutique_animate(Nb, s, r),
            c.text_front_only ||
              (k
                .find('.' + N)
                .boutique_stop()
                .boutique_animate({ fontSize: hb }, s, r),
              k
                .find('.' + M)
                .boutique_stop()
                .boutique_animate(Db, s, r)),
            setTimeout(function() {
              k.css('zIndex', 2)
            }, s / 4),
            (q = Q[v].clone()),
            q
              .addClass('frame5')
              .prependTo(x)
              .data('framesource', v)
              .show()
              .css({ opacity: 0, left: wb, top: X })
              .boutique_animate({ opacity: c.back_opacity }, s, function() {
                if (
                  (i
                    .children('a')
                    .find('*')
                    .css({ cursor: 'default' }),
                  c.autoplay && e(),
                  2 === b
                    ? f(1, 'last')
                    : b > 1
                    ? f(b - 1, 'normal')
                    : j
                        .children('a')
                        .find('*')
                        .css({ cursor: 'pointer' }),
                  !j.is(':animated') &&
                    ('first' !== g && (B = !1),
                    x
                      .children('.remove')
                      .boutique_stop()
                      .boutique_fadeout(s, function() {
                        a(this).remove()
                      }),
                    'function' == typeof move_callback))
                ) {
                  var d = j.children('a').attr('href')
                  d || (d = 'no_anchor'),
                    move_callback(d, A, o.data('framesource'))
                }
              })
              .find('.' + O)
              .css(Mb)
              .end()
              .children('a')
              .removeAttr('rel'),
            c.text_front_only
              ? q
                  .find('.' + N)
                  .css({ fontSize: hb })
                  .end()
                  .find('.' + M)
                  .css(Db)
                  .hide()
              : q
                  .find('.' + N)
                  .css({ fontSize: pb })
                  .end()
                  .find('.' + M)
                  .css(Eb),
            (m = h),
            (n = i),
            (o = j),
            (p = k),
            x.children('.remove').boutique_fadeout(s, function() {
              a(this).remove()
            }),
            D === E ? (D = 1) : (D += 1),
            !H && K && c.variable_container_height && Jb(!0)
        }
        function g(b, f) {
          var h,
            r,
            s = c.speed
          if (
            ((B = !0),
            (f = f || !1),
            f || (f = b > 1 ? 'first' : 'normal'),
            b > 1 && 'first' === f
              ? ((r = c.move_more_easein), (s = Math.round(0.7 * c.speed)))
              : b > 1
              ? ((r = c.move_more_easebetween), (s = Math.round(0.4 * c.speed)))
              : (r = 'last' === f ? c.move_more_easeout : c.easing),
            c.autoplay && d(),
            3 === D
              ? (v = E)
              : 2 === D
              ? ((v = E - 1), 1 > v && (v = E))
              : 1 === D
              ? ((v = E - 2), 1 > v && (v = E))
              : (v = D - 3),
            'function' == typeof pre_move_callback)
          ) {
            var t = n.children('a').attr('href')
            t || (t = 'no_anchor'),
              pre_move_callback(t, A, n.data('framesource'))
          }
          H && (x.boutique_stop(), c.text_front_only && o.find('.' + M).hide()),
            q
              .removeClass('frame5')
              .addClass('remove')
              .css('zIndex', -1),
            (l = p
              .removeClass('frame4')
              .addClass('frame5')
              .boutique_stop()
              .boutique_animate(
                { opacity: c.back_opacity, left: wb, top: X },
                s,
                r
              )),
            (h = l.find('.' + O).boutique_stop()),
            G && K && h.css({ height: 'auto' }),
            h.boutique_animate(Lb, s, r),
            c.text_front_only ||
              (l
                .find('.' + N)
                .boutique_stop()
                .boutique_animate({ fontSize: pb }, s, r),
              l
                .find('.' + M)
                .boutique_stop()
                .boutique_animate(Eb, s, r)),
            setTimeout(function() {
              l.css('zIndex', 1)
            }, s / 4),
            (k = o
              .removeClass('frame3')
              .addClass('frame4')
              .boutique_stop()
              .boutique_animate(
                { opacity: c.behind_opacity, left: xb, top: W },
                s,
                r
              )),
            k
              .find('.' + N)
              .boutique_stop()
              .boutique_animate({ fontSize: hb }, s, r),
            k
              .find('.' + M)
              .boutique_stop()
              .boutique_animate(Db, s, r),
            (h = k.find('.' + O).boutique_stop()),
            G && K && h.css({ height: 'auto' }),
            h.boutique_animate(Nb, s, r),
            setTimeout(function() {
              k.css('zIndex', 2)
            }, s / 4),
            (j = n
              .removeClass('frame2')
              .addClass('frame3')
              .boutique_stop()
              .boutique_animate(
                { opacity: 1, left: vb, top: V },
                s,
                r,
                function() {
                  H &&
                    c.text_front_only &&
                    a(this)
                      .find('.' + N)
                      .boutique_stop()
                      .css({ fontSize: _ })
                      .end()
                      .find('.' + M)
                      .boutique_stop()
                      .css(Cb)
                      .fadeIn(200),
                    H && K && c.variable_container_height && Jb(!0)
                }
              )),
            (h = j.find('.' + O).boutique_stop()),
            G && K && h.css({ height: 'auto' }),
            h.boutique_animate(Ob, s, r),
            (H && c.text_front_only) ||
              (j
                .find('.' + N)
                .boutique_stop()
                .boutique_animate({ fontSize: _ }, s, r),
              j
                .find('.' + M)
                .boutique_stop()
                .show()
                .boutique_animate(Cb, s, r)),
            setTimeout(function() {
              j.css('zIndex', 3)
            }, s / 4),
            (i = m
              .removeClass('frame1')
              .addClass('frame2')
              .boutique_stop()
              .boutique_animate(
                { opacity: c.behind_opacity, left: Y, top: W },
                s,
                r
              )),
            (h = i.find('.' + O).boutique_stop()),
            G && K && h.css({ height: 'auto' }),
            h.boutique_animate(Nb, s, r),
            c.text_front_only ||
              (i
                .find('.' + N)
                .boutique_stop()
                .boutique_animate({ fontSize: hb }, s, r),
              i
                .find('.' + M)
                .boutique_stop()
                .boutique_animate(Db, s, r)),
            setTimeout(function() {
              i.css('zIndex', 2)
            }, s / 4),
            (m = Q[v].clone()),
            m
              .addClass('frame1')
              .prependTo(x)
              .data('framesource', v)
              .show()
              .css({ opacity: 0, left: 0, top: X })
              .boutique_animate({ opacity: c.back_opacity }, s, function() {
                if (
                  (k
                    .children('a')
                    .find('*')
                    .css({ cursor: 'default' }),
                  c.autoplay && e(),
                  2 === b
                    ? g(1, 'last')
                    : b > 1
                    ? g(b - 1, 'normal')
                    : j
                        .children('a')
                        .find('*')
                        .css({ cursor: 'pointer' }),
                  !j.is(':animated') &&
                    ('first' !== f && (B = !1),
                    x
                      .children('.remove')
                      .boutique_stop()
                      .boutique_fadeout(s, function() {
                        a(this).remove()
                      }),
                    'function' == typeof move_callback))
                ) {
                  var d = j.children('a').attr('href')
                  d || (d = 'no_anchor'),
                    move_callback(d, A, o.data('framesource'))
                }
              })
              .find('.' + O)
              .css(Mb)
              .end()
              .children('a')
              .removeAttr('rel'),
            c.text_front_only
              ? m
                  .find('.' + N)
                  .css({ fontSize: hb })
                  .end()
                  .find('.' + M)
                  .css(Db)
                  .hide()
              : m
                  .find('.' + N)
                  .css({ fontSize: pb })
                  .end()
                  .find('.' + M)
                  .css(Eb),
            (n = i),
            (o = j),
            (p = k),
            (q = l),
            x.children('.remove').boutique_fadeout(s, function() {
              a(this).remove()
            }),
            1 === D ? (D = E) : (D -= 1),
            !H && K && c.variable_container_height && Jb(!0)
        }
        var h,
          i,
          j,
          k,
          l,
          m,
          n,
          o,
          p,
          q,
          r,
          s,
          t,
          u,
          v,
          w = a(window),
          x = a(this)
            .addClass('boutique')
            .show(),
          y = x.parent(),
          z = x.children('li'),
          A = x.attr('id'),
          B = !1,
          C = !1,
          D = c.starter,
          E = z.length,
          F = !1,
          G = !1,
          H = !1,
          I = !1,
          J = c.speed / 4,
          K = !1,
          L = 'boutique-frame',
          M = 'boutique-frame-text',
          N = 'boutique-frame-title',
          O = 'boutique-frame-image',
          P = 'boutique-frame-link'
        'object' == typeof a.browser &&
          a.browser.msie &&
          ((F = !0),
          a.browser.version < 9 &&
            ((H = !0), a.browser.version < 7 && (G = !0))),
          c.starter > E && (c.starter = E),
          c.right_to_left && x.addClass('rtl'),
          A || (A = 'no_id'),
          'behind_opac' in c && (c.behind_opacity = c.behind_opac),
          'back_opac' in c && (c.back_opacity = c.back_opac),
          'autointerval' in c && (c.autoplay_interval = c.autointerval),
          'hoverspeed' in c && (J = c.hoverspeed),
          (3 === c.frames || '3' === c.frames) &&
            ('auto' === c.behind_distance && (c.behind_distance = 0),
            (c.back_opacity = 0))
        var Q = [],
          R = []
        a.each(z, function(b) {
          b += 1
          var d,
            e,
            f,
            g = a(this).addClass(L + ' li' + b),
            h = g.children('a').addClass(P),
            i = g.find('img').addClass(O),
            j = 0,
            k = 0,
            l = 0,
            m = 0
          'auto' === c.front_img_height
            ? ((k = i.attr('width')),
              k || (k = i.width()),
              (l = parseInt(i.attr('height'), 10)),
              l || (l = i.height()),
              R.push(l),
              k &&
                c.front_img_width !== k &&
                ((j = c.front_img_width / k), (m = Math.floor(j * l))),
              g.data('displayheight', m))
            : g.data('displayheight', c.front_img_height),
            i.removeAttr('width').removeAttr('height'),
            (d = g.find('.' + N)),
            (e = d.length ? d.text() : i.attr('alt')),
            (f = g.find('.' + M)),
            f.length || (f = g.find('span').addClass(M)),
            f.length ||
              ((f = a(
                e
                  ? '<span class="' + M + '" />'
                  : '<span class="' + M + ' dummy" />'
              )),
              f.appendTo(h.length ? h : g)),
            e
              ? d.length || (d = a('<h6 class="' + N + '">' + e + '</h6>'))
              : (d = a('<h6 class="' + N + ' dummy" />')),
            d.prependTo(f),
            (Q[b] = g)
        }),
          'auto' === c.front_img_height
            ? ((K = !0), (u = 0))
            : (u = c.front_img_height),
          1 === E
            ? ((m = Q[1]
                .clone()
                .addClass('frame1')
                .prependTo(x)
                .data('framesource', 1)),
              (n = Q[1]
                .clone()
                .addClass('frame2')
                .prependTo(x)
                .data('framesource', 1)))
            : 2 === c.starter
            ? ((n = Q[1]
                .clone()
                .addClass('frame2')
                .prependTo(x)
                .data('framesource', 1)),
              (m = Q[E].clone()
                .addClass('frame1')
                .prependTo(x)
                .data('framesource', E)))
            : 1 === c.starter
            ? ((m = Q[E - 1]
                .clone()
                .addClass('frame1')
                .prependTo(x)
                .data('framesource', E - 1)),
              (n = Q[E].clone()
                .addClass('frame2')
                .prependTo(x)
                .data('framesource', E)))
            : ((n = Q[c.starter - 1]
                .clone()
                .addClass('frame2')
                .prependTo(x)
                .data('framesource', c.starter - 1)),
              (m = Q[c.starter - 2]
                .clone()
                .addClass('frame1')
                .prependTo(x)
                .data('framesource', c.starter - 2))),
          (o = Q[c.starter]
            .clone()
            .addClass('frame3')
            .prependTo(x)
            .data('framesource', c.starter)),
          1 === E
            ? ((p = Q[1]
                .clone()
                .addClass('frame4')
                .prependTo(x)
                .data('framesource', 1)),
              (q = Q[1]
                .clone()
                .addClass('frame5')
                .prependTo(x)
                .data('framesource', 1)))
            : c.starter === E - 1
            ? ((p = Q[E].clone()
                .addClass('frame4')
                .prependTo(x)
                .data('framesource', E)),
              (q = Q[1]
                .clone()
                .addClass('frame5')
                .prependTo(x)
                .data('framesource', 1)))
            : c.starter === E
            ? ((p = Q[1]
                .clone()
                .addClass('frame4')
                .prependTo(x)
                .data('framesource', 1)),
              (q = Q[2]
                .clone()
                .addClass('frame5')
                .prependTo(x)
                .data('framesource', 2)))
            : ((p = Q[c.starter + 1]
                .clone()
                .addClass('frame4')
                .prependTo(x)
                .data('framesource', c.starter + 1)),
              (q = Q[c.starter + 2]
                .clone()
                .addClass('frame5')
                .prependTo(x)
                .data('framesource', c.starter + 2))),
          m
            .add(n)
            .add(o)
            .add(p)
            .add(q)
            .children('a')
            .removeAttr('rel')
        var S = m
            .add(q)
            .show()
            .css({ opacity: 0 })
            .addClass('back'),
          T = n
            .add(p)
            .show()
            .css({ opacity: 0 })
            .addClass('behind'),
          U = o
            .show()
            .css({ opacity: 0 })
            .addClass('front')
        'auto' === c.container_width || '100%' === c.container_width
          ? ((I = !0), (s = parseInt(y.width(), 10)))
          : (s = c.container_width)
        var V = parseInt(U.css('marginTop'), 10),
          W = parseInt(T.css('marginTop'), 10),
          X = parseInt(S.css('marginTop'), 10)
        V || (V = parseInt(c.front_topmargin, 10)),
          W || (W = parseInt(c.behind_topmargin, 10)),
          X || (X = parseInt(c.back_topmargin, 10))
        var Y,
          Z = parseInt(z.css('borderLeftWidth'), 10),
          $ = parseInt(z.css('paddingLeft'), 10),
          _ = U.find('.' + N).css('fontSize'),
          ab = U.find('.' + M).css('fontSize'),
          bb = U.find('.' + O),
          cb = {
            top: parseInt(bb.css('marginTop'), 10),
            right: parseInt(bb.css('marginRight'), 10),
            bottom: parseInt(bb.css('marginBottom'), 10),
            left: parseInt(bb.css('marginLeft'), 10),
          },
          db = Math.round(
            c.front_img_width + cb.left + cb.right + 2 * $ + 2 * Z
          ),
          eb = Math.round(u + cb.top + cb.bottom + 2 * $ + 2 * Z),
          fb = Math.round(c.front_img_width * c.behind_size),
          gb = Math.round(u * c.behind_size),
          hb = T.find('.' + N).css('fontSize'),
          ib = T.find('.' + M).css('fontSize'),
          jb = T.find('.' + O),
          kb = {
            top: parseInt(jb.css('marginTop'), 10),
            right: parseInt(jb.css('marginRight'), 10),
            bottom: parseInt(jb.css('marginBottom'), 10),
            left: parseInt(jb.css('marginLeft'), 10),
          },
          lb = Math.round(fb + kb.left + kb.right + 2 * $ + 2 * Z),
          mb = Math.round(gb + kb.top + kb.bottom + 2 * $ + 2 * Z),
          nb = Math.round(c.front_img_width * c.back_size),
          ob = Math.round(u * c.back_size),
          pb = S.find('.' + N).css('fontSize'),
          qb = S.find('.' + M).css('fontSize'),
          rb = S.find('.' + O),
          sb = {
            top: parseInt(rb.css('marginTop'), 10),
            right: parseInt(rb.css('marginRight'), 10),
            bottom: parseInt(rb.css('marginBottom'), 10),
            left: parseInt(rb.css('marginLeft'), 10),
          },
          tb = Math.round(nb + sb.left + sb.right + 2 * $ + 2 * Z),
          ub = Math.round(ob + sb.top + sb.bottom + 2 * $ + 2 * Z),
          vb = Math.round(s / 2 - db / 2),
          wb = s - tb
        K && ((u = 'auto'), (gb = 'auto'), (ob = 'auto')),
          (Y =
            'auto' !== c.behind_distance
              ? parseInt(c.behind_distance, 10)
              : Math.round(s / 4 - lb / 2))
        var xb = s - Y - lb
        S.removeClass('back'), T.removeClass('behind'), U.removeClass('front')
        var yb = a('span', z).css('padding-top'),
          zb = a('span', z).css('padding-right'),
          Ab = a('span', z).css('padding-bottom'),
          Bb = a('span', z).css('padding-left'),
          Cb = {
            opacity: c.text_opacity,
            fontSize: ab,
            paddingTop: yb,
            paddingRight: zb,
            paddingBottom: Ab,
            paddingLeft: Bb,
          },
          Db = {
            opacity: c.text_opacity,
            fontSize: ib,
            paddingTop: Math.round(0.8 * parseInt(yb, 10)),
            paddingRight: Math.round(0.8 * parseInt(zb, 10)),
            paddingBottom: Math.round(0.8 * parseInt(Ab, 10)),
            paddingLeft: Math.round(0.8 * parseInt(Bb, 10)),
          },
          Eb = {
            opacity: c.text_opacity,
            fontSize: qb,
            paddingTop: Math.round(0.6 * parseInt(yb, 10)),
            paddingRight: Math.round(0.6 * parseInt(zb, 10)),
            paddingBottom: Math.round(0.6 * parseInt(Ab, 10)),
            paddingLeft: Math.round(0.6 * parseInt(Bb, 10)),
          }
        if (
          (c.text_front_only && ((Db.opacity = 0), (Eb = a.extend(Eb, Db))), G)
        ) {
          var Fb =
              parseInt(U.find('.' + M).css('margin-left'), 10) +
              parseInt(U.find('.' + M).css('margin-right'), 10),
            Gb =
              parseInt(T.find('.' + M).css('margin-left'), 10) +
              parseInt(T.find('.' + M).css('margin-right'), 10),
            Hb =
              parseInt(S.find('.' + M).css('margin-left'), 10) +
              parseInt(S.find('.' + M).css('margin-right'), 10)
          ;(Cb.width = db - parseInt(zb, 10) - parseInt(Bb, 10) - Fb - 2 * Z),
            (Db.width = lb - Db.paddingRight - Db.paddingLeft - Gb - 2 * Z),
            (Eb.width = tb - Eb.paddingRight - Eb.paddingLeft - Hb - 2 * Z)
        }
        x.find('.dummy').remove()
        var Ib = [],
          Jb = function(a) {
            ;(Ib = []),
              Ib.push(
                Math.floor(
                  parseInt(Q[m.data('framesource')].data('displayheight'), 10) *
                    c.back_size
                ) +
                  ub +
                  parseInt(X, 10)
              ),
              Ib.push(
                Math.floor(
                  parseInt(Q[n.data('framesource')].data('displayheight'), 10) *
                    c.behind_size
                ) +
                  mb +
                  parseInt(W, 10)
              ),
              Ib.push(
                parseInt(Q[D].data('displayheight'), 10) + eb + parseInt(V, 10)
              ),
              Ib.push(
                Math.floor(
                  parseInt(Q[p.data('framesource')].data('displayheight'), 10) *
                    c.behind_size
                ) +
                  mb +
                  parseInt(W, 10)
              ),
              Ib.push(
                Math.floor(
                  parseInt(Q[q.data('framesource')].data('displayheight'), 10) *
                    c.back_size
                ) +
                  ub +
                  parseInt(X, 10)
              ),
              a &&
                (Ib.sort(function(a, b) {
                  return b - a
                }),
                t !== Ib[0] &&
                  ((t = Ib[0]),
                  x
                    .boutique_stop()
                    .boutique_animate({ height: t }, c.speed, c.easing)
                    .css({ overflow: 'visible' })))
          }
        if (c.variable_container_height && K) Jb(!1)
        else if (K) {
          R.sort(function(a, b) {
            return b - a
          })
          var Kb = parseInt(R[0], 10)
          Kb || (Kb = 300),
            Ib.push(Kb + eb + parseInt(V, 10)),
            Ib.push(Kb + mb + parseInt(W, 10)),
            Ib.push(Kb + ub + parseInt(X, 10))
        } else
          Ib.push(eb + parseInt(V, 10)),
            Ib.push(mb + parseInt(W, 10)),
            Ib.push(ub + parseInt(X, 10))
        Ib.sort(function(a, b) {
          return b - a
        }),
          (t = Ib[0]),
          x.height(t).width(s),
          m
            .css({ left: 0, top: X, opacity: c.back_opacity })
            .find('.' + O)
            .css({
              width: nb,
              height: ob,
              marginTop: sb.top,
              marginRight: sb.right,
              marginBottom: sb.bottom,
              marginLeft: sb.left,
              opacity: 1,
            })
            .siblings('.' + M)
            .css(Eb)
            .children('.' + N)
            .css({ fontSize: pb }),
          n
            .css({ left: Y, top: W, zIndex: 2, opacity: c.behind_opacity })
            .find('.' + O)
            .css({
              width: fb,
              height: gb,
              marginTop: kb.top,
              marginRight: kb.right,
              marginBottom: kb.bottom,
              marginLeft: kb.left,
              opacity: 1,
            })
            .siblings('.' + M)
            .css(Db)
            .children('.' + N)
            .css({ fontSize: hb }),
          o
            .css({ left: vb, top: V, zIndex: 3, opacity: 1 })
            .find('a *')
            .css({ cursor: 'pointer' })
            .end()
            .find('.' + O)
            .css({
              width: c.front_img_width,
              height: u,
              marginTop: cb.top,
              marginRight: cb.right,
              marginBottom: cb.bottom,
              marginLeft: cb.left,
              opacity: 1,
            })
            .siblings('.' + M)
            .css(Cb)
            .children('.' + N)
            .css({ fontSize: _ }),
          p
            .css({ left: xb, top: W, zIndex: 2, opacity: c.behind_opacity })
            .find('.' + O)
            .css({
              width: fb,
              height: gb,
              marginTop: kb.top,
              marginRight: kb.right,
              marginBottom: kb.bottom,
              marginLeft: kb.left,
              opacity: 1,
            })
            .siblings('.' + M)
            .css(Db)
            .children('.' + N)
            .css({ fontSize: hb }),
          q
            .css({ left: wb, top: X, opacity: c.back_opacity })
            .find('.' + O)
            .css({
              width: nb,
              height: ob,
              marginTop: sb.top,
              marginRight: sb.right,
              marginBottom: sb.bottom,
              marginLeft: sb.left,
              opacity: 1,
            })
            .siblings('.' + M)
            .css(Eb)
            .children('.' + N)
            .css({ fontSize: pb }),
          c.text_front_only &&
            m
              .add(n)
              .add(p)
              .add(q)
              .find('.' + M)
              .hide(),
          G &&
            K &&
            (m.find('.' + O).css({
              height: Math.floor(
                Q[m.data('framesource')].data('displayheight') * c.back_size
              ),
            }),
            n.find('.' + O).css({
              height: Math.floor(
                Q[n.data('framesource')].data('displayheight') * c.behind_size
              ),
            }),
            o.find('.' + O).css({
              height: Math.floor(
                Q[o.data('framesource')].data('displayheight')
              ),
            }),
            p.find('.' + O).css({
              height: Math.floor(
                Q[p.data('framesource')].data('displayheight') * c.behind_size
              ),
            }),
            q.find('.' + O).css({
              height: Math.floor(
                Q[q.data('framesource')].data('displayheight') * c.back_size
              ),
            }))
        var Lb = {
          width: nb,
          marginTop: sb.top,
          marginRight: sb.right,
          marginBottom: sb.bottom,
          marginLeft: sb.left,
          opacity: 1,
        }
        K || (Lb.height = ob)
        var Mb = a.extend({}, Lb)
        K && (Mb.height = 'auto')
        var Nb = {
          width: fb,
          marginTop: kb.top,
          marginRight: kb.right,
          marginBottom: kb.bottom,
          marginLeft: kb.left,
          opacity: 1,
        }
        K || (Nb.height = gb)
        var Ob = {
          width: c.front_img_width,
          marginTop: cb.top,
          marginRight: cb.right,
          marginBottom: cb.bottom,
          marginLeft: cb.left,
          opacity: 1,
        }
        K || (Ob.height = u),
          ('undefined' != typeof a.easing.easeIOBoutique &&
            a.easing.easeIOBoutique) ||
            a.extend(a.easing, {
              easeInBoutique: function(a, b, c, d, e) {
                var f = b / e
                return f * f
              },
              easeOutBoutique: function(a, b, c, d, e) {
                var f = b / e
                return -1 * f * (f - 2)
              },
              easeIOBoutique: function(a, b, c, d, e) {
                var f = b / (e / 2)
                return 0.5 >= a ? (f / 2) * f : -0.5 * (--f * (f - 2) - 1)
              },
            }),
          I && b(),
          (window[A + '_ext_prev'] = window[A + '_prev'] = window[
            A + '_previous'
          ] = function(a) {
            ;(a = a || 1), c.right_to_left ? f(a) : g(a)
          }),
          (window[A + '_ext_next'] = window[A + '_next'] = function(a) {
            ;(a = a || 1), c.right_to_left ? g(a) : f(a)
          }),
          (window[A + '_stopautoplay'] = function() {
            c.autoplay && d(), (c.autoplay = !1)
          }),
          (window[A + '_startautoplay'] = function() {
            c.autoplay || e(), (c.autoplay = !0)
          }),
          (window[A + '_goto'] = function(a) {
            if (
              ((a = a || !1),
              a &&
                ((a = parseInt(a, 10)),
                parseFloat(a) === a && D !== a && E >= a))
            ) {
              var b,
                c = 0
              a > D
                ? D + (E - a) >= a - D
                  ? ((b = 'right'), (c = a - D))
                  : ((b = 'left'), (c = D + (E - a)))
                : D - a >= E - D + a
                ? ((b = 'right'), (c = E - D + a))
                : ((b = 'left'), (c = D - a)),
                'right' === b ? f(c) : g(c)
            }
          }),
          x.delegate('.frame1', 'click', function(a) {
            ;(c.freescroll || !B) &&
              (c.never_move_twice
                ? g(1)
                : c.move_more_directly
                ? (g(1), g(1))
                : g(2),
              a.preventDefault())
          }),
          x.delegate('.frame2', 'click', function(a) {
            ;(c.freescroll || !B) && (g(1), a.preventDefault())
          }),
          x.delegate('.frame4', 'click', function(a) {
            ;(c.freescroll || !B) && (f(1), a.preventDefault())
          }),
          x.delegate('.frame5', 'click', function(a) {
            ;(c.freescroll || !B) &&
              (c.never_move_twice
                ? f(1)
                : c.move_more_directly
                ? (f(1), f(1))
                : f(2),
              a.preventDefault())
          }),
          c.move_on_hover &&
            (x.delegate('.frame1', 'mousemove', function() {
              m.trigger('click')
            }),
            x.delegate('.frame2', 'mousemove', function() {
              n.trigger('click')
            }),
            x.delegate('.frame4', 'mousemove', function() {
              p.trigger('click')
            }),
            x.delegate('.frame5', 'mousemove', function() {
              q.trigger('click')
            }))
        var Pb = {
          left: '-=' + Math.floor(c.front_img_width * (c.hovergrowth / 2)),
          top: '-=' + Math.floor((c.front_img_width * c.hovergrowth) / 2),
        }
        K || (Pb.top = '-=' + Math.floor(u * c.hovergrowth))
        var Qb = { left: '-=' + Math.floor(fb * c.hovergrowth) },
          Rb = { left: '+=' + Math.floor(fb * c.hovergrowth) },
          Sb = { width: Math.floor(c.front_img_width * (1 + c.hovergrowth)) },
          Tb = { width: '+=' + Math.floor(c.hovergrowth * c.front_img_width) }
        K || (Sb.height = Math.floor(u * (1 + c.hovergrowth)))
        var Ub = { width: c.front_img_width }
        K || (Ub.height = u),
          x.delegate('.frame3', 'mouseenter mouseleave', function(a) {
            'mouseenter' !== a.type || B || C
              ? B ||
                ((C = !1),
                c.autoplay && e(),
                K &&
                  G &&
                  (Ub.height = Q[o.data('framesource')].data('displayheight')),
                o
                  .boutique_stop()
                  .boutique_animate({ left: vb, top: V }, J)
                  .find('.' + O)
                  .boutique_stop()
                  .boutique_animate(Ub, J, function() {
                    o.removeClass('zoomed')
                  }),
                n.boutique_stop().boutique_animate({ left: Y }, J),
                p.boutique_stop().boutique_animate({ left: xb }, J),
                G &&
                  x
                    .find('.zoomed .' + M)
                    .boutique_stop()
                    .boutique_animate(Cb, J))
              : ((C = !0),
                c.autoplay && d(),
                K &&
                  G &&
                  (Sb.height = Math.floor(
                    Q[o.data('framesource')].data('displayheight') *
                      (1 + c.hovergrowth)
                  )),
                o
                  .addClass('zoomed')
                  .boutique_stop(!0, !0)
                  .boutique_animate(Pb, J)
                  .find('.' + O)
                  .boutique_stop()
                  .boutique_animate(Sb, J),
                n.boutique_stop(!0, !0).boutique_animate(Qb, J),
                p.boutique_stop(!0, !0).boutique_animate(Rb, J),
                G &&
                  o
                    .find('.' + M)
                    .boutique_stop()
                    .boutique_animate(Tb, J))
          }),
          F ||
            x.delegate('.frame3:not(.zoomed)', 'mousemove', function() {
              C || o.trigger('mouseenter')
            }),
          x.delegate('.frame3 a', 'click', function(b) {
            'function' == typeof link_callback &&
              (link_callback(a(this).attr('href'), A) || b.preventDefault()),
              c.autoplay && c.stop_autoplay_on_click && (d(), (c.autoplay = !1))
          }),
          c.keyboard &&
            a(document).keydown(function(a) {
              x.is(':visible') &&
                ((39 !== a.keyCode &&
                  (c.right_to_left ||
                    (13 !== a.keyCode && 32 !== a.keyCode))) ||
                  f(1),
                (37 === a.keyCode ||
                  (c.right_to_left &&
                    (13 === a.keyCode || 32 === a.keyCode))) &&
                  g(1))
            }),
          c.autoplay && e(),
          I &&
            w.resize(function() {
              b()
            })
      }),
      this
    )
  }
})(jQuery)

// Slider callback
function move_callback(anchor, carouselId, index) {
  // Change active bullet
  jQuery('#boutiqueBullets li').removeClass('active')
  jQuery(`#boutiqueBullets li:nth-child(${index})`).addClass('active')
}

jQuery(function() {
  jQuery('#boutique').boutique({
    container_width: '688', // 'auto'
    front_img_width: 204,
    front_img_height: 'auto',
    variable_container_height: !0,
    frames: 3,
    starter: 1,
    speed: 600,
    front_topmargin: 40,
    behind_topmargin: 20,
    back_topmargin: 0,
    behind_opacity: 0.6,
    back_opacity: 0.15,
    behind_size: 0.85,
    back_size: 0.4,
    behind_distance: 'auto',
    autoplay: !1,
    autoplay_interval: 3e3,
    stop_autoplay_on_click: !1,
    freescroll: !0,
    hovergrowth: 0, // 0.08
    easing: 'easeIOBoutique',
    move_more_easein: 'easeInBoutique',
    move_more_easebetween: 'linear',
    move_more_easeout: 'easeOutBoutique',
    move_more_directly: !1,
    never_move_twice: !1,
    text_front_only: !1,
    text_opacity: 0.7,
    keyboard: !0,
    move_on_hover: !1,
    right_to_left: !1,
  })

  // jQuery(window).on('resize', () => {
  // Maybe set adaptive for slider
  // })
})
